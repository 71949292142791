import React from 'react';
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';
import logo from '../../../assets/icons/logo.svg';
import classNames from 'classnames';
import styles from './styles.module.scss';

export default function Header() {
  return (
    <header>
      <section className={classNames(styles['header'], 'container')}>
        <Link to="/">
          <ReactSVG src={logo} className={styles['header__logo']} />
        </Link>
      </section>
    </header>
  );
}
