import React from 'react';
import Header from '../../atoms/Header';
import Footer from '../../molecules/Footer';

export default function Privacy() {
  return (
    <>
      <Header />
      <main className="container terms-page">
        <h1>Privacy Policy</h1>
        <p>
          In the following policy, Collabri refers to the service offered by COLLABRI LTD (the
          "Company" or "We") through the <a href="//www.collabri.app">website</a> and the Collabri
          application (the "Service"). This Privacy Policy explains (i) what information the Company
          collects through user access and use of the Service (ii) how we make of such information;
          and (iii) the security level we provide for protecting such information.
        </p>
        <p>
          By visiting <a href="//www.collabri.app">collabri.app</a>, the Collabri application and
          using the Services provided here, you agree to the terms outlined in this privacy policy.
        </p>
        <section>
          <h3>Legal Framework</h3>
          <p>The Company is domiciled in the United Kingdom at the following address:</p>
          <p>COLLABRI LTD 85 Great Portland Street, First Floor, London W1W 7LT</p>
          <p>
            All data storage infrastructure is provided by Amazon web services{' '}
            <a href="https://aws.amazon.com">https://aws.amazon.com</a>.
          </p>
        </section>
        <section>
          <h3>Data related to the opening of an account</h3>
          <p>
            Any email address provided to Collabri is considered personal data as defined and
            protected by the Data Protection Act 2018.
          </p>
          <p>
            Such data will only be used to contact you with important notifications about Collabri,
            to send you information related to security, to send you an invitation link to create
            your Collabri account, to verify your Collabri account, or to send you password recovery
            links. We may also inform you about new Collabri products in which you might have an
            interest. As the basis of processing of this data is consent, you are free, at any given
            time, to opt out of those features through the account settings panel.
          </p>
          <p>
            In order to maintain the integrity of the service, Collabri must take measures to avoid
            creation of accounts by spammers.
          </p>
          <p>
            In order to pursue our legitimate interest of preventing the creation of accounts by
            spam bots or human spammers, Collabri uses a human verification method by email.
          </p>
        </section>
        <section>
          <h3>Data Collection</h3>
          <p>
            The Company’s overriding policy is to collect as little user information as possible to
            ensure a completely private user experience when using the Service.
          </p>
          <p>Service's user data collection is limited to the following:</p>
          <p>
            <strong>Account creation:</strong> It is not necessary to provide personal information
            other than a user name and an email address in order to create an account. The external
            email address is also used for notification or password recovery purposes. The legal
            basis for processing is consent and you are free to delete your account in the settings
            panel of your Collabri account.
          </p>
          <p>
            <strong>Account activity:</strong> We have access to the records of account activity
            related to the creation, application and solicitation of ads.
          </p>
          <p>
            <strong>Communicating with Collabri:</strong> Your communications with the Company, such
            as support requests, bug reports, or feature requests, may be saved by our staff. The
            legal basis for processing is our legitimate interest to troubleshoot more efficiently
            and improve the quality of the Collabri service.
          </p>
          <p>
            <strong>IP Logging:</strong> Collabri does not keep permanent IP logs. However, IP logs
            may be kept temporarily to combat abuse and fraud, and your IP address may be retained
            permanently if you are engaged in activities that breach our terms and conditions
            (spamming, DDoS attacks against our infrastructure, brute force attacks, etc). The legal
            basis of this processing is our legitimate interest to protect our service against
            nefarious activities.
          </p>
          <p>
            <strong>Native Applications:</strong> When you use our Collabri application, we (or the
            mobile app platform providers) may collect certain information in addition to the
            information mentioned elsewhere in this Policy. We may use mobile analytics software
            (such as fabric.io app statistics and crash reporting, Play Store app statistics, App
            Store app statistics, or self-hosted Sentry crash reporting) to send crash information
            to our developers so that we can fix bugs rapidly. Some platforms (such as the Google
            Play Store or the Apple App Store) may also collect aggregate, anonymous statistics like
            which type of devices and operating systems that are most commonly used (like percentage
            of Android 6.x vs Android 7.x), the total number of installs, total number of
            uninstalls, and the total number of active users, and may be governed by the privacy
            policy and terms and conditions of the Google Play Store or the Apple App Store. Any
            personal data acquired during this process are on an anonymous basis.
          </p>
        </section>
        <section>
          <h3>Data Use</h3>
          <p>
            We do not have any advertising on our site. Any data that we do have will never be
            shared except under the circumstances described below in the Data Disclosure Section.
          </p>
        </section>
        <section>
          <h3>Data Storage</h3>
          <p>
            All servers used in connection with the provisioning of the Service are located in
            Frankfurt and operated by Amazon Web Services.
          </p>
        </section>
        <section>
          <h3>
            Right to Access, Rectification, Erasure, Portability, and right to lodge a complaint
          </h3>
          <p>
            Through the Service, you can directly access, edit, delete or export personal data
            processed by the Company in your use of the Service.
          </p>
          <p>
            If your account has been suspended for a breach of our terms and conditions, and you
            would like to exercise the rights related to your personal data, you can make a request
            to our support team.
          </p>
          <p>
            In case of violation of your rights, you have the right to lodge a complaint to the
            competent supervisory authority.s
          </p>
        </section>
        <section>
          <h3>Data Retention</h3>
          <p>
            When a Collabri account is deleted, data are removed from production servers and deleted
            after a period of 30 days. Active accounts will have data retained indefinitely. Deleted
            chats are also permanently deleted from production servers.
          </p>
        </section>
        <section>
          <h3>Data Disclosure</h3>
          <p>
            We will only disclose the limited user data we possess if we are instructed to do so by
            a fully binding request coming from the competent UK authorities (legal obligation).
            While we may comply with electronically delivered notices (see exceptions below), the
            disclosed data can only be used in court after we have received an original copy of the
            court order by registered post or in person, and provide a formal response.
          </p>
          <p>
            If permitted by law, Collabri will always contact a user first before any data
            disclosure.
          </p>
          <p>
            Collabri may, from time to time, contest requests if there is a public interest in doing
            so. In such situations, the Company will not comply with the request until all legal or
            other remedies have been exhausted.
          </p>
        </section>
        <section>
          <h3>Modifications to Privacy Policy</h3>
          <p>
            Collabri reserves the right to periodically review and change this policy from time to
            time and we will notify users who have enabled the notification preference about changes
            to our Privacy Policy. Continued use of the Service will be deemed as acceptance of such
            changes.
          </p>
        </section>
      </main>
      <Footer />
    </>
  );
}
