import React from 'react'
import ReactDOM from 'react-dom'
import './sass/styles.scss'
import App from './components/App'
import ScrollToTop from './components/prototypes/ScrollToTop'
import { Router } from 'react-router-dom'
import history from './routes/history'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <Router history={history}>
    <ScrollToTop />
    <App />
  </Router>,
document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
