import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../../atoms/Header';
import { getQueryParam } from '../../../utils/params';

class RedirectMobile extends Component {
  constructor(props) {
    super(props);
    this.location = props.location;
    this.type = getQueryParam(this.location.search, 'action');
  }

  componentDidMount() {
    setTimeout(() => {
      if (window.confirm('Press OK to open the Application and continue')) {
        document.location.href = `collabri://${this.location.search}`;
      }
    }, 1200);
  }

  render() {
    return (
      <>
        <Header />
        <main className="container terms-page redirect-page">
          <h3>
            {this.type === 'verify-email' ? 'Activate your account' : 'Reset your password'}
          </h3>
          <p>Press OK in the prompt window to open the Collabri app</p>
          <p>If no prompt is displayed click on the button below</p>
          <a href={`collabri://${this.location.search}`} target="_blank" rel="alternate">
            <button type="submit">Open Collabri</button>
          </a>
        </main>
      </>
    );
  }
}

export default withRouter(RedirectMobile);
