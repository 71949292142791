import React from 'react';
import Header from '../../atoms/Header';
import Footer from '../../molecules/Footer';
import { Link } from 'react-router-dom';

export default function Terms() {
  return (
    <>
      <Header />
      <main className="container terms-page">
        <h1>Terms and Conditions</h1>
        <p>
          By using the collabri.app web site and making use of Collabri application, a service
          offered by COLLABRI LTD (the "Company"), you are agreeing to be bound by the following
          Terms and Conditions. These Terms and Conditions cover all present and future features
          offered by your Collabri account, individually and collectively referred to as the
          "Service”.
        </p>
        <p>
          If you are agreeing to these Terms and Conditions on behalf of a company or another legal
          entity, you represent that you have the authority to bind such entity, its affiliates and
          all users who access the Service through your account to these Terms and Conditions. In
          the absence of such an authority, you may not use the Service.
        </p>
        <section>
          <h3>Users of the Service</h3>
          <p>
            This Service is provided exclusively to individuals who are at least 18 years of age, or
            to minors who have obtained parental or legal guardian consent to open and maintain an
            account.
          </p>
          <p>
            This Service is provided exclusively to persons. Accounts registered by “bots” or
            automated methods are not authorized and will be terminated.
          </p>
          <p>
            Each user is solely responsible for all of his or her messages sent through the Service.
          </p>
        </section>
        <section>
          <h3>Use of the Service</h3>
          <p>
            You agree not to use this Service for any unlawful or prohibited activities. You also
            agree not to disrupt the Collabri networks and servers.
          </p>
          <p>
            You further agree not to use Collabri to send spam, or contact or solicit persons
            without the intention to enter into a transactional relationship. Any account found to
            be sending the aforementioned type of emails will be immediately suspended.
          </p>
          <p>
            For the purpose of these Terms and Conditions, Spam shall refer to irrelevant or
            unsolicited messages sent over the internet, typically to large numbers of users, for
            the purposes of advertising, phishing, spreading malware or virus ("Spam").
          </p>
          <p>
            We may also terminate accounts which are being used for illegal activity, particularly
            in response to court orders from the competent authorities informing us of such illegal
            activity.
          </p>
          <p>
            The Company has no obligation to store or forward the contents of terminated accounts.
          </p>
          <p>
            Although it is not the current practice, we reserve the right to suspend or delete
            accounts that are inactive over a longer period of time.
          </p>
        </section>
        <section>
          <h3>Data protection</h3>
          <p>
            All information on Collabri that is made available from users is only ever used by
            Collabri to operate the platform and is deleted in full after the user account is
            deleted. The data are only used to operate Collabri and we do not make it available in
            the public domain. Any legal regulations to the contrary remain reserved.
          </p>
          <p>
            Users can request to see their stored data at any time and can inspect, amend and delete
            this data themselves at any time.
          </p>
          <p>The aforementioned principles are supported by the privacy policy.</p>
        </section>
        <section>
          <h3>Liability disclaimer</h3>
          <p>
            Collabri is not responsible for the actions or failures of its users. We do not accept
            any liability in relation to the contractual relationship between users.
          </p>
          <p>
            Collabri is excluded from liability, where such exclusion is permissible by law, in the
            event of any damages suffered by the users due to a breach of duties by the other user.
          </p>
        </section>
        <section>
          <h3>Warranty disclaimer</h3>
          <p>
            Users shall be obliged to refrain from infringing any third party copyright or other
            protective rights. If Collabri identifies that such an infringement has been carried out
            by the user, we reserve us the right to immediately delete the user’s account.
          </p>
          <p>
            Any warranty claims in relation to services rendered by a user exist exclusively against
            the user. Collabri gives no warranty and assumes no liability of any kind for the
            services rendered by users.
          </p>
        </section>
        <section>
          <h3>Value added tax and social security contributions</h3>
          <p>
            Collabri is the operator of the app. It brings users together on the app and digitizes
            their daily tasks. Collabri does not make money or earn a fee or commission from any
            task being proposed or executed. Collabri is therefore neither responsible nor liable
            for any VAT being truthfully declared.
          </p>
          <p>
            Users confirm that they fully comply with the appropriate fiscal duties and liabilities.
          </p>
          <p>
            Users also confirm that they fully comply with all obligations to pay social security
            contributions and all other remaining legal obligations in relation to transactions
            carried out from interactions on Collabri.
          </p>
          <p>
            In addition, users confirm that they are providing the services they are offering in
            their own name, on their own behalf, as an independent entity and at their own financial
            risk.
          </p>
          <p>
            The Company cannot make any warranty about the reliability of the Service or guarantee
            the security of user data despite best efforts. The Service is provided “as is” and
            users agree neither to hold the Company responsible nor to seek indemnification for any
            damages that may arise as a result of the loss of use, data, or profits connected to the
            performance of the Service or failure in such performance.
          </p>
          <p>
            Furthermore, you will not hold the Company liable or seek indemnification if data are
            unintentionally released as a result of a security failure or vulnerability in the
            performance of the Service.
          </p>
          <p>
            We may make improvements and changes to the Service at any time without notice.
            <br />
            The Company may at its sole discretion, terminate service without cause or notice.
          </p>
        </section>
        <section>
          <h3>Indemnification</h3>
          <p>
            You agree that the Company, and any parents, subsidiaries, officers, employees, or third
            party contractors cannot be held responsible for any third party claim, demand, or
            damages, including reasonable legal fees, arising out of your use of this Service.
          </p>
        </section>
        <section>
          <h3>Privacy</h3>
          <p>
            Our <Link to="/privacy-policy">Privacy Policy</Link> explains the way we handle and
            protect your personal data and privacy in relation to your use of the Service and your
            browsing of the Collabri app and <a href="//www.collabri.app">web site</a>. By agreeing
            to the present Terms and Conditions and to be able to use the Service, you also agree to
            our Privacy Policy.
          </p>
          <p>
            To the extent that Collabri processes any personal data that is subject to the EU
            General Data Protection Regulation (GDPR), on the user’s behalf, in the provision of the
            Service, the terms of the Collabri Data Processing Agreement, shall apply.
          </p>
        </section>
        <section>
          <h3>Modification to Terms of Service</h3>
          <p>
            Within the limits of applicable law, the Company reserves the right to review and change
            this agreement at any time. Users are responsible for regularly reviewing these Terms
            and Conditions. Continued use of the Service after such changes shall constitute your
            consent to such changes.
          </p>
        </section>
        <section>
          <h3>Applicable Law</h3>
          <p>
            This Agreement shall be governed in all aspects by the substantive laws of England and
            Wales. Any controversy, claim, or dispute arising out of or relating to the Agreement
            shall be subject to the jurisdiction of the competent courts.
          </p>
        </section>
        <section>
          <h3>Liability Notice</h3>
          <p>
            Despite carefully monitoring all contents, the Company does not assume any liability for
            the contents of external links. Operators of external contents or links shall be solely
            responsible for the contents of the linked websites. Some type errors may be present.
          </p>
        </section>
      </main>
      <Footer />
    </>
  );
}
