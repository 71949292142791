import React from 'react';
import ReactSVG from 'react-svg';
import appStore from '../../../assets/icons/app_store.svg';
import gPlay from '../../../assets/images/google_play.png';
import phoneTop from '../../../assets/images/phone-top.png';
import phone1 from '../../../assets/images/phone1.png';
import phone2 from '../../../assets/images/phone2.png';
import phone3 from '../../../assets/images/phone3.png';
import phone4 from '../../../assets/images/phone4.png';
import phone5 from '../../../assets/images/phone5.png';
import bird from '../../../assets/images/D_Logo.png';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Header from '../../atoms/Header';
import Footer from '../../molecules/Footer';

export default function Home() {
  return (
    <>
      <Header />
      <div className={styles['collabri']}>
        <main>
          <div className={styles['collabri__top']}>
            <div className={styles['collabri__top__bg']} />
            <div className={styles['collabri__top__bgg']} />
            <img className={styles['collabri__top__bg-logo']} src={bird} alt="" />
            <div className="container--fluid">
              <h1 className={styles['collabri__heading']}>
                The peer to peer platform for small jobs
              </h1>
              <div
                className={classNames(styles['collabri__promo'], styles['collabri__promo--top'])}
              >
                <div className={styles['collabri__promo__image']}>
                  <img src={phoneTop} alt="" />
                </div>
                <div
                  className={classNames(
                    styles['collabri__promo__text'],
                    styles['collabri__promo__text--white'],
                  )}
                >
                  <div
                    className={classNames(
                      styles['collabri__promo__divider'],
                      styles['collabri__promo__divider--white'],
                    )}
                  />
                  <h3>
                    Take control
                    <br />
                    of your work life
                  </h3>
                  <p>
                    The Collabri app lets you find
                    <br />
                    cool jobs and motivated helpers.
                  </p>
                  <div className={styles['collabri__promo__download']}>
                    <div className={styles['collabri__download__btns']}>
                      <a
                        href="https://apps.apple.com/ch/app/collabri/id1501893428?l=en"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>
                          <ReactSVG src={appStore} />
                        </div>
                      </a>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.collabri&hl=en_US"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div>
                          <img src={gPlay} alt="" />
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles['collabri__middle']}>
            <div className={styles['collabri__middle__bg']} />
            <div className="container">
              <div
                className={classNames(styles['collabri__promo'], styles['collabri__promo--left'])}
              >
                <div className={styles['collabri__promo__image']}>
                  <img src={phone1} alt="" />
                </div>
                <div className={styles['collabri__promo__text']}>
                  <div className={styles['collabri__promo__divider']} />
                  <h3>Bring you closer to what you need</h3>
                  <h5>Personalise your search.</h5>
                  <p>Filter and sort to find the right job or the right person</p>
                  <p>
                    Tap on the funnel button at the bottom of your screen and enable filters to see
                    jobs and helpers based on locations, radius, hourly wage expectations and
                    ratings.
                  </p>
                </div>
              </div>

              <div className={styles['collabri__promo']}>
                <div className={styles['collabri__promo__image']}>
                  <img src={phone2} alt="" />
                </div>
                <div className={styles['collabri__promo__text']}>
                  <div className={styles['collabri__promo__divider']} />
                  <h3>Discover jobs near you</h3>
                  <h5>Use the interactive map.</h5>
                  <p>
                    Tap on the map icon at the bottom of your screen to search for jobs and helpers
                    on the interactive map. You can navigate the map to explore other cities. Tap on
                    any icon to see work and help ads and apply directly.
                  </p>
                </div>
              </div>

              <div
                className={classNames(
                  styles['collabri__promo'],
                  styles['collabri__promo--left'],
                  styles['mb-0'],
                )}
              >
                <div className={styles['collabri__promo__image']}>
                  <img src={phone3} alt="" />
                </div>
                <div className={styles['collabri__promo__text']}>
                  <div className={styles['collabri__promo__divider']} />
                  <h3>Do what you are passionate about, have your helper focus on the rest</h3>
                </div>
              </div>
            </div>
          </div>

          <div className={styles['collabri__bottom']}>
            <div className={styles['collabri__bottom__bg']} />
            <div className="container">
              <div className={styles['collabri__promo']}>
                <div className={styles['collabri__promo__image']}>
                  <img src={phone4} alt="" />
                </div>
                <div className={styles['collabri__promo__text']}>
                  <div className={styles['collabri__promo__divider']} />
                  <h3>Stay flexible, create your own opportunities</h3>
                  <p>
                    Create your own ads to promote your talents and skills and build up your
                    reputation.
                  </p>
                  <p>Manage your listing schedules and change them as needed.</p>
                </div>
              </div>

              <div
                className={classNames(styles['collabri__promo'], styles['collabri__promo--left'])}
              >
                <div className={styles['collabri__promo__image']}>
                  <img src={phone5} alt="" />
                </div>
                <div className={styles['collabri__promo__text']}>
                  <div className={styles['collabri__promo__divider']} />
                  <h3>Chat, plan and communicate freely</h3>
                  <p>
                    Stay updated on any development, by receiving notifications and direct messages.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className={styles['collabri__download']}>
              <h2>Register now and find cool jobs and motivated helpers</h2>
              <div className={styles['collabri__download__btns']}>
                <a
                  href="https://apps.apple.com/ch/app/collabri/id1501893428?l=en"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>
                    <ReactSVG src={appStore} />
                  </div>
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.collabri&hl=en_US"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>
                    <img src={gPlay} alt="" />
                  </div>
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Footer />
    </>
  );
}
