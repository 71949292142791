import React from 'react';
import styles from './styles.module.scss';

export default function Menu(props) {
  return (
    <ul className={styles['menu']}>
      {props.children.length ? (
        props.children.map((child, index) => {
          return <li key={index}>{child}</li>;
        })
      ) : (
        <li>{props.children}</li>
      )}
    </ul>
  );
}
