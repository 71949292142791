import React from 'react';
import Header from '../../atoms/Header';
import Footer from '../../molecules/Footer';

export default function About() {
  return (
    <>
      <Header />
      <main className="container terms-page">
        <h1>About</h1>
        <section>
          <p>
            Collabri is a mobile application on iOS and Android devices that provides a peer to peer
            marketplace for services encompassing small gigs and jobs as well as leisure activities.
            The application connects two user groups: on the one hand, individuals requiring
            specific services and on the other hand, those that are capable of delivering them.
          </p>
          <p>
            As such, Collabri is a platform that not only provides a one-stop shop for those looking
            for help on a variety of tasks, but also enables their counterparts to promote
            themselves by creating ads, communicating and transacting. Collabri aims to become a
            “one-click gateway” for everything people can offer.
          </p>
        </section>
      </main>
      <Footer />
    </>
  );
}
