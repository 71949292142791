import React from 'react';
import { Route } from 'react-router-dom';
import withTracker from '../components/hocs/withTracker';

import Home from '../components/pages/Home';
import Terms from '../components/pages/Terms';
import Privacy from '../components/pages/Privacy';
import About from '../components/pages/About';
import RedirectMobile from '../components/pages/RedirectMobile';

export default [
  <Route key="home" path="/" component={withTracker(Home)} exact />,
  <Route key="terms" path="/terms-&-conditions" component={withTracker(Terms)} exact />,
  <Route key="privacy" path="/privacy-policy" component={withTracker(Privacy)} exact />,
  <Route key="about" path="/about" component={withTracker(About)} exact />,
  <Route key="reset-password" path="/reset-password" component={withTracker(RedirectMobile)} exact />,
];
