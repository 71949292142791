import React from 'react';
import ReactSVG from 'react-svg';
import { Link } from 'react-router-dom';
import Menu from '../../atoms/Menu';
import logo from '../../../assets/icons/logo.svg';
import linkedIn from '../../../assets/icons/linkedin.svg';
import faceBook from '../../../assets/icons/facebook.svg';
import twitter from '../../../assets/icons/twitter.svg';
import insta from '../../../assets/icons/instagram.svg';
import classNames from 'classnames';
import styles from './styles.module.scss';

export default function Footer() {
  return (
    <footer className={classNames(styles['footer'], 'container')}>
      <div className={styles['footer__top']}>
        <Link to="/">
          <ReactSVG src={logo} className={styles['footer__logo']} />
        </Link>
        <div className={styles['footer__right']}>
          <div className={styles['footer__menu']}>
            <h5>Contact</h5>
            <Menu>
              <p>Collabri Ltd.</p>
              <p>85 Great Portland Street, First Floor</p>
              <p>London, W1W 7LT</p>
              <a href="mailto:info@collabri.app">info@collabri.app</a>
            </Menu>
          </div>
          <div className={styles['footer__menu']}>
            <h5>Install</h5>
            <Menu>
              <a
                href="https://play.google.com/store/apps/details?id=com.collabri&hl=en_US"
                target="_blank"
                rel="noopener noreferrer"
              >
                Android
              </a>
              <a
                href="https://apps.apple.com/ch/app/collabri/id1501893428?l=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                iOS
              </a>
            </Menu>
          </div>
          <div className={styles['footer__menu']}>
            <h5>Company</h5>
            <Menu>
              <Link to="/about">About</Link>
              {/*<a href='/'>*/}
              {/*  Carrers*/}
              {/*</a>*/}
              {/*<a href='/'>*/}
              {/*  Contact us*/}
              {/*</a>*/}
            </Menu>
          </div>
          <div className={styles['footer__menu']}>
            <h5>Legal</h5>
            <Menu>
              {/*<a href='/'>*/}
              {/*  Information*/}
              {/*</a>*/}
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-&-conditions">Terms & Conditions</Link>
            </Menu>
          </div>
        </div>
      </div>
      <div className={styles['footer__divider']} />
      <div className={styles['footer__bottom']}>
        <small>&copy; Collabri Ltd. All rights reserved</small>
        <div className={styles['footer__bottom_social']}>
          <a
            href="https://www.linkedin.com/company/collabri"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <ReactSVG src={linkedIn} />
            </div>
          </a>
          <a href="https://www.facebook.com/collabriApp" target="_blank" rel="noopener noreferrer">
            <div>
              <ReactSVG src={faceBook} />
            </div>
          </a>
          <a href="https://twitter.com/AppCollabri" target="_blank" rel="noopener noreferrer">
            <div>
              <ReactSVG src={twitter} />
            </div>
          </a>
          <a
            href="https://www.instagram.com/collabri.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div>
              <ReactSVG src={insta} />
            </div>
          </a>
        </div>
      </div>
    </footer>
  );
}
