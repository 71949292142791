import React, { Component } from 'react';
import { Switch } from 'react-router-dom';
import routes from '../routes';
import ReactGA from 'react-ga';
import { DEFAULT_GA_CONFIG } from '../constants';

export default class App extends Component {
  constructor(props) {
    super(props);
    ReactGA.initialize(DEFAULT_GA_CONFIG);
  }

  render() {
    return (
      <>
        <Switch>{routes}</Switch>
      </>
    );
  }
}
