/**
 * Get a query param value from a query string
 *
 * @param {string} params - query string
 * @param {string} paramName - name of query param (key)
 * @param {any} defaultData - value returned if param not found
 * @return {string | *|*}
 */
export const getQueryParam = (params, paramName, defaultData) => {
  if (params[0] === '?') {
    params = params.substring(1);
  }
  const vars = params.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === paramName) {
      let result = decodeURIComponent(pair[1]).replace(/\+/g, ' ');
      return result || defaultData;
    }
  }
  return defaultData;
};
